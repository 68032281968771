import React from 'react'
//import { useParams } from 'react-router-dom'
import {Link} from 'react-router-dom';
import './search.css';
class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          currentSelectedYear: 0, currentSelectedMonth: 0
        };

        this.data = {};
        this.gap = {'padding-top':'20px'};
        this.changeSelectedYear = this.changeSelectedYear.bind(this);
        this.searchChange = this.searchChange.bind(this);
    }

    componentDidMount() {
      //console.log("fetching")
      fetch(`${window.location.origin}/json/${this.props.match.params.type}.json`)
      .then(res => res.json())
      .then(result => {          
        this.data = result;
        this.setState({ isLoaded: true, currentSelectedYear: 0 });
      }).catch(function (err) {
        this.setState({ error: err });
          return Promise.reject(err)
        }
      );
    }

    // Re-force the page to reload if changing from articles to poems etc
    componentWillReceiveProps(_) { window.location = window.location }

    changeSelectedYear(year) {

        let currentYears = document.querySelector("#years")

        currentYears.children[this.state.currentSelectedYear].children[0].classList.toggle('is-active')
        currentYears.children[year].children[0].classList.toggle('is-active')

        this.setState({currentSelectedYear: year, currentSelectedMonth: 0})
    }

    getChangeMonth(number, year)
    {
        //console.log(number, year)

        var dates = document.querySelector("#dates")

        dates.children[this.state.currentSelectedMonth].classList.toggle('is-active')
        dates.children[number].classList.toggle('is-active')

        this.setState({currentSelectedMonth: number})
        this.getPanel(year)
    }

    searchChange(e) {
      //this.setState({ searchValue: e.target.value });
      //console.log(e.target.value)
      const filter_out_class = 'panel-hidden'
      const force_hidden_class = 'panel-force-hide'
      const force_shown_class = 'panel-force-show'

      let resetFilter_forceHidden = Array.from(document.getElementsByClassName(force_hidden_class));
      let resetFilter_forceShown = Array.from(document.getElementsByClassName(force_shown_class));

      if(resetFilter_forceHidden.length > 0)
        resetFilter_forceHidden.forEach( t => {
            t.classList.remove(force_hidden_class)
        })

      if(resetFilter_forceShown.length > 0)
      resetFilter_forceShown.forEach( t => {
            t.classList.remove(force_shown_class)
        })

      if(e.target.value.length === 0) 
      {
        document.getElementById('not_found').classList.add(filter_out_class)
        document.getElementById('not_found').innerHTML = ""
        return;
      }

                             // Get all the articles from that year (including hidden ones)
      let availableStories = Array.from(document.querySelectorAll("#target"))
                             // Then only return back the visible stories that we want to filter
                            .filter(x => { return !x.classList.contains(filter_out_class); })
                 
      // validate if its worth while
      if(availableStories.length > 0)
      {
          document.getElementById('not_found').classList.add(filter_out_class)
          availableStories.map(x => x.classList.add(force_hidden_class))
          
          // Now filter based on text result
          var any_matches = availableStories.filter(x => { return x.innerText.toLowerCase().startsWith(e.target.value.toLowerCase())})

          if(any_matches.length > 0)
          {
             //panel-force-show
             any_matches.map(x => {
               x.classList.remove(force_hidden_class)
               x.classList.add(force_shown_class)
            })
            return;
          }
         
      } 
       
      document.getElementById('not_found').classList.remove(filter_out_class)
      document.getElementById('not_found').innerHTML = `No ${this.props.match.params.type}s with title:&emsp;<b>${e.target.value}</b>`
    }

    getPanel(year) {

      let stories = this.data[year];

      let AvailableDates = []
      let month_number = -1;

      stories.forEach(element => {
          month_number = element['date'][0]['month']
          if(!AvailableDates.includes(month_number))
            AvailableDates.push(month_number)
      });

      const Months = {
         1: 'Jan', '01': 'Jan',
         2: 'Feb', '02': 'Feb',
         3: 'Mar', '03': 'Mar',
         4: 'Apr', '04': 'Apr',
         5: 'May', '05': 'May',
         6: 'Jun', '06': 'Jun',
         7: 'Jul', '07': 'Jul',
         8: 'Aug', '08': 'Aug',
         9: 'Sep', '09': 'Sep',
         10: 'Oct',
         11: 'Nov',
         12: 'Dec'
      }
      
      AvailableDates.sort( function ( a, b ) { return a - b; } );
      let CompareDate = AvailableDates[this.state.currentSelectedMonth]
      return (
      <div class="column">
                <nav class="panel">
                  <p class="panel-heading" style={{"text-transform": "capitalize"}}>
                    Available {this.props.match.params.type}s ({year})
                  </p>
                  <div class="panel-block">
                    <p class="control has-icons-left">
                      <input class="input" type="text" placeholder="Search" onChange={this.searchChange} />
                      <span class="icon is-left">
                        <i class="fas fa-search" aria-hidden="true"></i>
                      </span>
                    </p>
                  </div>
                  <p class="panel-tabs" id="dates">
                    {
                      AvailableDates.map( (m, index) => (
                        <a className={(index === 0) ? "is-active" : null } onClick={() => this.getChangeMonth(index, year)}>{Months[m]}</a>
                      ))
                    }
                  </p>
                  {
                    stories.map(d => (
                    <a className={(d['date'][0]['month'] === CompareDate) ? "panel-block" : "panel-block panel-hidden"} 
                       id="target">
                      <Link to={`/view/${d.id}`}>{d.title}</Link>            
                    </a>
                    ))               
                  }
                  <a id="not_found" className={"panel-block panel-hidden"}>
                     <p>NOT FOUND</p>
                  </a>
                </nav>
                </div>)
    }

    render(){

        if(!this.state.isLoaded)
        {
            return (
              <div className="container" style={this.gap}>      
                <div>
                  <h2 className="title">Search</h2>
                  <h2 className="title is-5" style={{"text-transform": "capitalize"}}>Searching by {this.props.match.params.type}</h2>
                </div>   
                    
                <br/>
                <p>loading</p>
              </div>
            
            );
        }

        var availableYears = Object.keys(this.data);
        
        availableYears.sort( function ( a, b ) { return b - a; } );

        return (
            <div className="container" style={this.gap}>      

            <div>
              <h2 className="title">Search</h2>
              <h2 className="title is-5" style={{"text-transform": "capitalize"}}>Searching by {this.props.match.params.type}</h2>
            </div>   
                
            <br/>

            <div class="columns">
              <div class="column is-one-fifth">
                <aside class="menu">
                  <p class="menu-label">
                    Available Years
                  </p>
                  <ul class="menu-list" id="years">
                  {
                    Object.keys(availableYears).map((key, index) => ( 
                      <li><a className={( index === 0 ) ? "is-active" : null } onClick={() => this.changeSelectedYear(index) }>{availableYears[key]}</a></li>
                    ))
                  }
                  </ul>           
                </aside>
              </div>
              {this.getPanel(availableYears[this.state.currentSelectedYear])}
              
            </div>
            <br />
            </div>    
        )   
    } 
}

export default Search