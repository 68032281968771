import React from 'react'

import '../About.css'

// Images for the about page
let image1 = process.env.PUBLIC_URL + "/Images/henderson.jpg"
let image2 = process.env.PUBLIC_URL + "/Images/henderson2.jpg"
let image3 = process.env.PUBLIC_URL + "/Images/henderson3.jpg"
// let image4 = process.env.PUBLIC_URL + "/Images/henderson4.jpg"
let grand = process.env.PUBLIC_URL + "/Images/grand.jpg"

// Date the calculate the years they would have been married for
var date = new Date()

// Get the current year (from server)
const year = date.getFullYear();

// Get the date they were married (2nd September 1961)
const married_day = new Date(1961, 9, 2)

// Get the number of years since that date
let married_date = Number((date.getTime() - married_day.getTime()) / 31536000000).toFixed(0);

// Subtract the year by 1 if the month is below 9 (Not on or above September)
married_date = (date.getMonth() < 9) ? --married_date : married_date

const About = () => {
    return(

    <div className="container">         
        <h4 className="title" style={{"padding-top": "20px", textAlign: "center"}}>About Dr. Henderson Lightbody</h4>
        
        <div>
            <p class="subtitle is-4" style={{"padding": "3%", textAlign: "center"}}>
                Henderson Lightbody was born on the 29 March 1937, the sixth child of seven to George and Mary Lightbody.
                His full name is John Henderson Lightbody; John after his Mother's brother and Henderson after his Mother's grandfather Henderson.
                His parents decided to call their sixth child the Christian name Henderson, Henderson Lightbody's working life started off as a clerical officer with British Rail as it was called back in the 1950`s.
                He was employed with British Rail for almost six years; Interrupted by two years in the army during National Service in Malaya, (1955-57).
            </p>
        </div>

        <div class="card card-256">
            <div className={"card-content card-fix"}>
                <div className={"card-image card-item-fix"}>
                    <figure className={"image"}>
                        <img className={"image-256"} src={image1}/>
                    </figure>
                </div>
                <div className={"content"}>
                    <p class="subtitle is-4 center">
                        He was a full - time student of the former Bible Training Institute
                        in Glasgow. After Graduation he entered full-time ministry as a
                        church pastor, then a port missionary with Seaman's Christian Friend Society.
                        Henderson also studied religious knowledge through the Emmaus Bible School,
                        Chicago, Illinois USA.
                    </p>
                </div>
            </div>
        </div>

        <div class="card card-256">
            <div className={"card-content card-fix"}>
                <div className={"card-image card-item-fix"}>
                    <figure className={"image"}>
                        <img className={"image-256"} src={image2}/>
                    </figure>
                </div>
                <p class="subtitle is-4 center">
                    Some year's later he Graduated from Jordanhill College of Education where he completed a course on Community Education Studies, he then joined the Community Education Services and after twenty one years with the C.E.S. he took early retirement in 1992.
                </p>
            </div>
        </div>

        <div class="card card-256">
            <div className={"card-content card-fix"}>
                <div className={"card-image card-item-fix"}>
                    <figure className={"image"}>
                        <img className={"image-256"} src={image3}/>
                    </figure>
                </div>
                <p class="subtitle is-4 center">
                    Most of his retirement has been spent in writing pursuits including a 90.000 word dissertation on the History and Doctrines of the Christian Religion. (Submitted to Aberdeen University - Highly commended by Professor David S Fergusson) He has also written numerous articles for various local and national newspapers and magazines.
                </p>
            </div>
        </div>

        <div>
            <p class="subtitle is-4" style={{"padding": "3%", textAlign: "center"}}>
                He has also written two books of poetry entitled: Exit! from the Fast Lane, which is currently in many libraries in Scotland, and the latest book called A Plain Man's Escape. In addition he has also completed a non-fictional book entitled Hunches, Prejudices and Perplexities. Many of his poems have been published by various publishers. Much of his work has received high appraisal in editorial reviews. Henderson has also written papers on various subjects, including motoring, football, politics as well as religion and Christianity in particular.
            </p>
        </div>

        <div class="card card-256">
            <div className={"card-content card-fix"}>
                <div className={"card-image card-item-fix"}>
                    <figure className={"image"}>
                        <img className={"image-256"} src={grand}/>
                    </figure>
                </div>
                <p class="subtitle is-4 center">
                Henderson's main attributes have been described as those of philosopher, theologian, poet, writer, psychologist, sociologist, all of these characteristics and more are woven into his work whether its prose or verse..<br/><br />

                Henderson would have been married for {married_date} years this year ({year}), to Margaret an R.G.N. They have six children, three sons and three daughters and seven grandchildren.
                </p>
            </div>
        </div>
    </div>

    )
}

export default About