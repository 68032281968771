//import logo from './logo.svg';

//import './App.css';
import "./../node_modules/bulma/css/bulma.css";

import { HashRouter as Router, Route } from 'react-router-dom'

import Navbar from './Components/navbar';
import Footer from './Components/footer'

import Home from './Pages/Home'
import About from './Pages/About'
import Search from './Pages/Search'
import Story from './Pages/Story'

function App() {
  return (
    <div className="App">      
       <Router>
          <Navbar/>
          <Route exact path='/'              component={Home}   />
          <Route       path='/about'         component={About}  />
          <Route       path='/search/:type'  component={Search} />
          <Route       path='/view/:id'    component={Story}  />
        </Router>
        <div class="container card">
          <Footer />
        </div>
        
    </div>
  );
}

export default App;
