import React from 'react'

const Home = () => {
    return(
        <section>
            <div className="container" style={{paddingTop: '20px', paddingBottom: '20px'}}>         
                <p className={"subtitle is-3"}><b>Dr. Henderson Lightbody</b></p>
                <br/>
                <p className={"subtitle is-4"}>Welcome to <b>Dr. Henderson Lightbody's Website!</b></p>
                <br></br>
                <p className={"subtitle is-6"}>This is the brand new updated version of the website. Lots have changed!</p>
                <p className={"subtitle is-6"}>The website now runs a new modern architecture behind it (Front and Backend!) </p>

                <p className={"subtitle is-6"}>This is still new, lots of things are needed to be coded and thrown at it to make it perfect - but at least it runs (for now) </p>
            </div>
        </section>       
    )
}

export default Home