import React from "react";

const Footer = () => {
  return (
    <div>
      <footer class="footer" style={{"padding-bottom": "10px"}}>
        <div class="content has-text-centered">
          <p>
            <strong>© Dr. Henderson Lightbody 2021</strong><br />
            Web Site originally designed by <b>Richard Greig (2006-2013)</b><br /> Maintained by <b>James Greig (2020 - onwards)</b><br />
            Any issues or problems regarding the website, please contact: <a href="mailto:webmaster@hlightbody.co.uk">webmaster</a><br />
            To contact Dr. Henderson Lightbody directly, please contact: <a href="mailto:drhlightbody@yahoo.com">here</a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;